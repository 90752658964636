<template>
  <RuesBoxSize :size="props.item?.width" class="text-left grid grid-cols-1 gap-y-4 product-carousel">
    <UiHeadline v-if="props.item?.headline" type="h2" design="h2">
      {{ props.item?.headline }}
    </UiHeadline>
    <UiHeadline v-else type="h2" design="h2">
      {{ t('headline') }}
    </UiHeadline>
    <div class="transition-all ease-in-out delay-150 min-h-[230px] lg:min-h-[270px] xl:min-h-[307px] 1xl:min-h-[307px] 2xl:min-h-[306px] select-none">
      <div v-show="loaded" class="relative">
        <swiper
          :modules="modules"
          :breakpoints="{
            '768': {
              slidesPerView: 2.3
            },
            '976': {
              slidesPerView: 3
            },
          }"
          :slides-per-view="1.3"
          :space-between="0"
          :loop="true"
          direction="horizontal"
          :lazy="true"
          class="h-full w-full"
          @swiper="onSwiper"
        >
          <swiper-slide v-for="article in props.item?.objekte" :key="article.id" class="w-full px-2 py-3">
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="false"
              :lastminute="true"
              class="w-full sm:hidden block"
              :item="article"
            />
            <LazySharedObjectCard
              :nested-carousel="true"
              design="objectslider"
              :lazy-loading="true"
              :builder-carousel="true"
              :lastminute="true"
              class="w-full hidden sm:block"
              :item="article"
            />
          </swiper-slide>
        </swiper>
        <div class="swiper-button-prev" @click.stop.prevent="swiperRef.slidePrev()" />
        <div class="swiper-button-next" @click.stop.prevent="swiperRef.slideNext()" />
      </div>
      <div v-if="!loaded" class="flex flex-nowrap gap-x-4 w-full overflow-hidden">
        <SkeletonSharedObjectCard design="objectslider" class="w-[75%] md:w-[41.7%] lg:w-[33.3333%]" />
        <SkeletonSharedObjectCard design="objectslider" class="md:w-[41.7%] lg:w-[33.3333%] hidden md:block" />
        <SkeletonSharedObjectCard design="objectslider" class="lg:w-[33.3333%] hidden lg:block" />
        <SkeletonSharedObjectCard design="objectslidersmall" class="w-[22%] md:w-[12.2%] block lg:hidden" />
      </div>
    </div>
    <div>
      <div class="inline-block">
        <UiButton href="#" color="primary">
          {{ t('all_last_minute_offers') }}
        </UiButton>
      </div>
    </div>
  </RuesBoxSize>
</template>

<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper/modules'
import 'swiper/css'

const t = useTranslations('RuesProductLastminuteCarousel').translate

const loaded = ref(false)
const swiperRef = ref()
const onSwiper = (swiper) => {
  swiperRef.value = swiper
}
const modules = [Navigation]
if (import.meta.client) {
  onMounted(() => {
    loaded.value = true
  })
}
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
</script>
<style src="./scss/RuesProductCarousel.scss" scoped></style>
