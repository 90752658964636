<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <a :href="props.item?.angebot?.url">
          <img
            loading="lazy"
            :src="props.item?.angebot?.thumb"
            alt="Lastminute"
            height="auto"
            width="auto"
            :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
          >
          <div
            class="absolute bottom-0 right-0 mb-3 mr-6 bg-cta-700 rounded-xl text-white py-2 px-3 z-10"
          >
            <span>{{ props.item?.angebot?.prozent_ersparnis }}%</span>
            <UiLanguagePlaceholder domain="RuesCardLastminute" name="discount" />!
          </div>
        </a>
      </template>
      <template #title>
        <UiLanguagePlaceholder domain="RuesCardReviews" name="headline_lastminute" />
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <div>
            <UiLanguagePlaceholder
              domain="RuesCardLastminute"
              name="date"
              :variables="{ start: vonDate, end: bisDate }"
            />
          </div>
          <div>
            <div>
              <UiLanguagePlaceholder domain="RuesCardLastminute" name="ort" :variables="{ ort: props.item?.angebot?.ort}" />
            </div>
            <div>
              <UiLanguagePlaceholder domain="RuesCardLastminute" name="price" :variables="{ price: props.item?.angebot?.tagespreis}" />
            </div>
            <a :href="'/lastminute/'" class="text-petrol-600">
              <UiLanguagePlaceholder domain="RuesCardLastminute" name="link" :variables="{ offers: props.item?.alle_angebote}" />
            </a>
          </div>
          <div class="text-base self-end">
            <UiButton color="primary" :href="props.item?.angebot?.url">
              <UiLanguagePlaceholder domain="RuesCardLastminute" name="btn_lastminute" />
            </UiButton>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})

function formatDate (timestamp) {
  if (!timestamp) {
    return ''
  }
  const date = new Date(timestamp * 1000)
  let day = date.getDate()
  let month = date.getMonth() + 1
  if (day < 10) {
    day = '0' + day
  }
  if (month < 10) {
    month = '0' + month
  }
  return `${day}.${month}.`
}
const vonDate = formatDate(props.item?.angebot?.zeitraumvon?.timestamp)
const bisDate = formatDate(props.item?.angebot?.zeitraumbis?.timestamp)
</script>

<style lang="scss">

</style>
