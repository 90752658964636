<template>
  <RuesBoxSize :size="props.item?.width">
    <SharedCard>
      <template #img>
        <img
          v-if="props.item?.type == 'fahrrad'"
          loading="lazy"
          src="https://img.travanto.de/travanto/teaser/teaser_fahrrad.png"
          alt="Fahrrad"
          height="auto"
          width="auto"
          :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
        >
        <img
          v-if="props.item?.type == 'mietwagen'"
          loading="lazy"
          src="https://img.travanto.de/travanto/teaser/teaser_sylt_mietwagen.png"
          alt="Mietwagen"
          height="auto"
          width="auto"
          :class="[ 'aspect-[16/9] object-cover object-center w-full' ]"
        >
      </template>
      <template #title>
        {{ props.item?.header }}
      </template>
      <template #text>
        <div class="grid gap-3 h-full">
          <SearchFilterTravelDates :params="searchParams" @update="update($event)" />
          <div class="flex gap-4 justify-between mt-4 text-base self-end">
            <div>
              <img loading="lazy" src="https://img.travanto.de/travanto/teaser/teaser_payments.png" alt="Bezahlung">
            </div>
            <div class="justify-self-end self-end">
              <UiMaskedLink
                :href="generateURL()"
                :new-tab="true"
              >
                <UiButton color="primary">
                  <UiLanguagePlaceholder v-if="props.item?.type == 'fahrrad'" domain="RuesCardExternalSearch" name="fahrrad" />
                  <UiLanguagePlaceholder v-if="props.item?.type == 'mietwagen'" domain="RuesCardExternalSearch" name="mietwagen" />
                </UiButton>
              </UiMaskedLink>
            </div>
          </div>
        </div>
      </template>
    </SharedCard>
  </RuesBoxSize>
</template>

<script setup>
const props = defineProps({
  item: {
    type: Object,
    default: null
  }
})
const searchParams = reactive({
  von: null,
  bis: null
})
const update = (event) => {
  Object.entries(event).forEach(([key, value]) => {
    searchParams[key] = value
  })
}

const generateURL = () => {
  let url = props.item?.url || ''
  if (searchParams.von !== null) {
    url += `?von=${searchParams.von}`
  }
  if (searchParams.bis !== null) {
    url += `${url.includes('?') ? '&' : '?'}bis=${searchParams.bis}`
  }
  return url
}
</script>

<style scoped>

</style>
